<template>
    <div class="lf_Top_1">
        <el-row>
            <template v-if="match_set!=undefined">
                <!-- <template v-if="match_set.adv!=undefined&&match_set.adv.length>0" v-for="(item,index) in match_set.adv">
                    <el-col :xs="24" :sm="8" :md="8">
                        <img :src="match_set.adv[index].picurl" class="adlogo"/>
                    </el-col>
                </template>
				<swiper-slide v-if="match_set.adv!=undefined&&match_set.adv.length>0" v-for="(item,index) in match_set.adv">
				    <img :src="match_set.adv[index].picurl" class="adlogo"/>
				</swiper-slide>
                <el-col v-else :xs="24" :sm="8" :md="8">
                    <img src="../assets/images/adv.png" class="adlogo"/>
                </el-col> -->
				<div class="swiperBox" v-if="match_set.adv!=undefined&&match_set.adv.length==1">
					<img :src="match_set.adv[0].picurl" class="adlogo"/>
				</div>
				<div class="swiperBox" v-else>
					<swiper
					  direction="'vertical'"
					  :loop="true"
					  :autoplay="{ autoplay: true, delay: 2400 }"
					  slidesPerView="auto"
					  :spaceBetween="0"
					  :centeredSlides="true"
					  :modules="modules"
					>
					  <swiper-slide v-if="match_set.adv!=undefined&&match_set.adv.length>0" v-for="(item,index) in match_set.adv">
						<img :src="match_set.adv[index].picurl" class="adlogo"/>
					  </swiper-slide>
					  <swiper-slide v-else>
							<img v-if="systemlang=='cn'" src="../assets/images/adv.png" class="adlogo"/>
							<img v-else-if="systemlang=='en'" src="../assets/images/adven.jpeg" class="adlogo"/>
							<img v-else="systemlang=='tw'" src="../assets/images/advtw.jpeg" class="adlogo"/>
					  </swiper-slide>
					</swiper>
			  </div>
            </template>
        </el-row>
    </div>
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
	import {
	    Swiper,
	    SwiperSlide
	} from 'swiper/vue/swiper-vue';
	
	import {
	    Autoplay,Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	//自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                banner:{},
                cj:{}

            };
        },
        name: 'Ad',
		components:{
			Swiper,
			SwiperSlide,
		},
        props: {
            msg: String,
            match_set: Object
        },
		setup() {
		    const onSwiper = (swiper) => {
		        console.log(swiper);
		    };
		    const onSlideChange = (e) => {
		        console.log('slide change123123123',e.activeIndex);
		    };
		    return {
		        onSwiper,
		        onSlideChange,
		        modules: [Autoplay],
		    };
		},
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
            var match_set = this.match_set;
			console.log(this.systemlang);
        },
		

    }
</script>
<style>
	.swiper-wrapper{
		display: block;
	}
</style>
<style scoped>
    .adlogo{
		width: 100%;
		height: 1.4rem;
	}
	.swiper{
		width: 100%;
		height: 1.4rem;
		flex-direction: column;
	}
	.swiper-slide {
	  height: 1.4rem;
	  line-height: 100vh;
	  font-size: 30px;
	  text-align: center;
	  background-color: skyblue;
	  
	}
	
	@media screen and (max-width: 768px) {
		
	}
</style>
