<template>
    <!-- <div v-if="show1" class="show1">
        	<img src="../../src/assets/images/lloading.gif" alt="">
        </div> -->
    <div class="lf_new_top_">
        <div class="lf_new_top_box" style="position: relative; padding: 0">
            <div style="color: #fff; margin: 0 0 0 1%; margin-left: 0.4rem; font-size: 0.3rem">
                {{ langs["Pos."] }} {{ player["su_rank"] }} {{ langs["ming"] }}
                {{ player["pl_cn_name"] }}
                <template v-if="match.mt_amateur == '1'">
                    {{ player["pl_type"] }}
                </template>
            </div>
            <a class="lf_back_h" href="javascript:window.history.back();" target="_self" v-if="backshow">{{
                langs["Back"]
            }}</a>
        </div>
    </div>
    <div id="score_content">
        <div class="lf_match_top_box" style="overflow: hidden">
            <span class="lf_playerscore_1" style="font-size: 0.4rem">
                <img
                    v-if="match.mt_country_show_type == '1'"
                    class="lf_playerscore_country_img"
                    :src="
                        'https://overview.wifigolf.com/images/flags/changurl.php?mt_id=' +
                        mt_id +
                        '&content=64/' +
                        player['pl_country'] +
                        '.png'
                    "
                    alt=""
                />
                <div class="lf_country_text" v-else-if="match.mt_country_show_type == '2'">
                    {{ player["pl_country"] }}
                </div>
                {{ player["pl_cn_name"] }}
                <template v-if="match.mt_amateur == '1'">
                    {{ player["pl_type"] }}
                </template>
                <div
                    v-if="match.tee_show_type != '0'"
                    style="
                        border-radius: 50%;
                        font-size: 13px;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        margin-left: 0.2rem;
                        margin-top: 2px;
                        background: #ccc;
                    "
                >
                    {{ player["mp_tee"] }}
                </div>
                <template v-if="match.mt_sex_show_type != '0'">
                    <img
                        style="width: 0.3rem"
                        v-if="
                            (match.mt_sex_show_type == 'female' || match.mt_sex_show_type == 'both') &&
                            player['pl_sex'] == 0
                        "
                        class="lf_playerscore_country_img"
                        src="../assets/images/u9.png"
                        alt=""
                    />
                    <img
                        style="width: 0.3rem"
                        v-if="
                            (match.mt_sex_show_type == 'male' || match.mt_sex_show_type == 'both') &&
                            player['pl_sex'] == 1
                        "
                        class="lf_playerscore_country_img"
                        src="../assets/images/u7.png"
                        alt=""
                    />
                </template>
                <span v-if="player.su_to_par > 0" style="background: #025287"
                    >+{{ player["su_to_par"] }}</span
                >
                <span v-else-if="player.su_to_par == 0" style="background: #53565b">E</span>
                <span v-else style="background: #b72a47">{{ player["su_to_par"] }}</span>
            </span>
            <div class="span_hui hui_tits lf_mt_name_c_box">
                <div class="lf_mt_name_c">{{ match["mt_cn_name"] }}</div>
                <div>{{ match["mt_date"] }}</div>
            </div>
        </div>
        <div v-if="0" class="lf_go_jcsp_box">
            <div class="lf_video_box"><img src="__PUBLIC__/img/u447.png" alt="" /></div>
            <div class="lf_video_name">{$pl_name} <span>de</span>{{ langs["Highlights"] }}</div>
            <img class="lf_video_right" src="__PUBLIC__/img/u117.png" alt="" />
        </div>
        <div style="width: 7rem; margin: 0.2rem auto">
            <div class="lf_round_C_T1">
                <div class="lf_round_C">
                    <div class="lf_round_C_bg1"></div>
                    <div class="lf_round_C_bg2"></div>
                    <div
                        @click="getDataLoading(mt_id, item.su_rd_id, pl_id)"
                        v-for="(item, index) in roundsummary"
                        :style="{ maxWidth: roundsummary.length >= 4 ? '1.1rem' : '1.45rem' }"
                        :class="
                            'lf_round_C_1 ' + [item.su_rd_id == rd_id ? 'lf_round_C_1_select' : '']
                        "
                    >
                        <div class="lf_round_C_tit">R{{ item.su_rd_id }}</div>
                        <div class="lf_round_C_con">{{ item.su_score }}</div>
                    </div>
                    <div class="lf_round_C_Tot">
                        <div class="lf_round_C_tit">Total</div>
                        <div class="lf_round_C_con">{{ roundtotal.su_score }}</div>
                    </div>
                </div>
                <div
                    class="lf_per_score_box"
                    style="width: 7rem; position: relative; left: -0.2rem; margin-top: 0.3rem"
                >
                    <div class="lf_per_score_box_tit">
                        <div>HOLE</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index < 9">{{ item.sc_ho_id }}</div>
                        </template>
                        <div>OUT</div>
                    </div>
                    <div class="lf_per_score_box_par">
                        <div>{{ langs["Par"] }}</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index < 9">{{ item.sc_par }}</div>
                        </template>
                        <div>{{ holeout.sc_par }}</div>
                    </div>
                    <div class="lf_per_score_box_score">
                        <div>{{ langs["Scorechengji"] }}</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index < 9" style="padding: 5px 0">
                                <div :class="'lf_score_box_box lf_' + item.style">
                                    {{ item.sc_score }}
                                </div>
                            </div>
                        </template>
                        <div style="color: #333; font-weight: 700">{{ holeout.sc_score }}</div>
                    </div>
                    <div class="lf_per_score_box_tit" style="border-radius: 0">
                        <div>HOLE</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index >= 9">{{ item.sc_ho_id }}</div>
                        </template>
                        <div>IN</div>
                    </div>
                    <div class="lf_per_score_box_par">
                        <div>{{ langs["Par"] }}</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index >= 9">{{ item.sc_par }}</div>
                        </template>
                        <div>{{ holein.sc_par }}</div>
                    </div>
                    <div class="lf_per_score_box_score" style="border-bottom: 1px solid #e3e3e3">
                        <div>{{ langs["Scorechengji"] }}</div>
                        <template v-for="(item, index) in holescore">
                            <div v-if="index >= 9" style="padding: 5px 0">
                                <div :class="'lf_score_box_box lf_' + item.style">
                                    {{ item.sc_score }}
                                </div>
                            </div>
                        </template>
                        <div style="color: #333; font-weight: 700">{{ holein.sc_score }}</div>
                    </div>
                </div>
            </div>
            <div
                class="lf_round_C_T1"
                style="margin-top: 0.3rem; padding: 0; width: 7rem; overflow: hidden"
            >
                <div
                    id="main"
                    ref="chart"
                    style="width: 300px; height: 240px; margin: 0 auto"
                ></div>
            </div>
            <div
                class="lf_go_more"
                v-if="!showloading"
                @click="getDataLoading(mt_id, rd_id, pl_id)"
            >
                {{ langs["View More"] }}
            </div>
            <div
                class="lf_round_C_T1"
                v-if="showloading"
                style="margin-top: 0.3rem; padding: 0; width: 7rem; overflow: hidden"
            >
                <div
                    id="main1"
                    ref="chart1"
                    style="width: 320px; height: 260px; margin: 0 auto"
                ></div>
                <div class="lf_pross_box">
                    <div :style="'width:' + (scorenum.eagle / countscorenum) * 100 + '%;'"></div>
                    <div :style="'width:' + (scorenum.birdie / countscorenum) * 100 + '%;'"></div>
                    <div :style="'width:' + (scorenum.par / countscorenum) * 100 + '%;'"></div>
                    <div :style="'width:' + (scorenum.borgey / countscorenum) * 100 + '%;'"></div>
                    <div
                        :style="'width:' + (scorenum.doubleborgey / countscorenum) * 100 + '%;'"
                    ></div>
                    <div :style="'width:' + (scorenum.other / countscorenum) * 100 + '%;'"></div>
                </div>
                <div class="lf_pross_box lf_pross_box1">
                    <div style="color: #e3b102">
                        {{ langs["Eagle"] }}-{{ scorenum.eagle }}{{ langs["ge"] }}
                    </div>
                    <div style="color: #b72a47">
                        {{ langs["Birdie"] }}-{{ scorenum.birdie }}{{ langs["ge"] }}
                    </div>
                    <div style="color: #333">
                        {{ langs["Par"] }}-{{ scorenum.par }}{{ langs["ge"] }}
                    </div>
                    <div style="color: #025287">
                        {{ langs["Bogey"] }}-{{ scorenum.borgey }}{{ langs["ge"] }}
                    </div>
                    <div style="color: #5808c3">
                        {{ langs["D.Bogey"] }}-{{ scorenum.doubleborgey }}{{ langs["ge"] }}
                    </div>
                    <div style="color: #d7d7d7">
                        {{ langs["Other"] }}-{{ scorenum.other }}{{ langs["ge"] }}
                    </div>
                </div>
                <div
                    id="main2"
                    ref="chart2"
                    style="width: 320px; height: 360px; margin: 0 auto; display: none"
                ></div>
            </div>
            <div class="lf_scorecc_box" v-if="showloading" style="width: 7rem">
                <div class="lf_scorecc_box_1">
                    <div>{{ langs["Par 3 Scores"] }}</div>
                    <div>{{ langs["Par 4 Scores"] }}</div>
                    <div>{{ langs["Par 5 Scores"] }}</div>
                </div>
                <div class="lf_scorecc_box_2">
                    <div>
                        <span>{{ scorenum["3gan"] }}</span
                        ><span>({{ allscorenum["3gan"] }})</span>
                    </div>
                    <div>
                        <span>{{ scorenum["4gan"] }}</span
                        ><span>({{ allscorenum["4gan"] }})</span>
                    </div>
                    <div>
                        <span>{{ scorenum["5gan"] }}</span
                        ><span>({{ allscorenum["5gan"] }})</span>
                    </div>
                </div>
                <div class="lf_scorecc_box_1">
                    <div>{{ langs["Front 9"] }}</div>
                    <div>{{ langs["Back 9"] }}</div>
                    <div>{{ langs["TOTAL"] }}</div>
                </div>
                <div class="lf_scorecc_box_2" style="margin-bottom: 0.35rem">
                    <div>
                        <span>{{ scorenum["out"] }}</span
                        ><span>({{ allscorenum["out"] }})</span>
                    </div>
                    <div>
                        <span>{{ scorenum["in"] }}</span
                        ><span>({{ allscorenum["in"] }})</span>
                    </div>
                    <div>
                        <span>{{ scorenum["total"] }}</span
                        ><span>({{ allscorenum["total"] }})</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="lf_round_C_T1"
            v-if="showloading"
            style="margin: 0.3rem auto; padding: 0; width: 7rem; overflow: hidden"
        >
            <div class="lf_stats_content">
                <div class="lf_stats_tit_t">
                    <div>{{ langs.Performance }}</div>
                    <div>R{{ rd_id }}</div>
                    <div>{{ langs["TOT Rounds"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Par3s</div>
                    <div>{{ scorenum["3ganscore"] }}</div>
                    <div>{{ scorenum["match3ganscore"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Par4s</div>
                    <div>{{ scorenum["4ganscore"] }}</div>
                    <div>{{ scorenum["match4ganscore"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Par5s</div>
                    <div>{{ scorenum["5ganscore"] }}</div>
                    <div>{{ scorenum["match5ganscore"] }}</div>
                </div>
            </div>
        </div>
        <div
            class="lf_round_C_T1"
            v-if="showloading"
            style="margin: 0.3rem auto; padding: 0; width: 7rem; overflow: hidden"
        >
            <div class="lf_stats_content">
                <div class="lf_stats_tit_t">
                    <div>{{ langs["Result Stats"] }}</div>
                    <div>R{{ rd_id }}</div>
                    <div>{{ langs["TOT Rounds"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Eagle or Betters</div>
                    <div>{{ scorenum["eagle"] }}</div>
                    <div>{{ scorenum["matcheagle"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Birdies</div>
                    <div>{{ scorenum["birdie"] }}</div>
                    <div>{{ scorenum["matchbirdie"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Pars</div>
                    <div>{{ scorenum["par"] }}</div>
                    <div>{{ scorenum["matchpar"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>Bogeys</div>
                    <div>{{ scorenum["borgey"] }}</div>
                    <div>{{ scorenum["matchborgey"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>D.bogeys+</div>
                    <div>{{ scorenum["doubleborgey"] + scorenum["other"] }}</div>
                    <div>{{ scorenum["matchdoubleborgey"] + scorenum["matchother"] }}</div>
                </div>
            </div>
        </div>
        <div
            class="lf_round_C_T1"
            v-if="showloading"
            style="margin: 0.3rem auto; padding: 0; width: 7rem; overflow: hidden"
        >
            <div class="lf_stats_content">
                <div class="lf_stats_tit_t">
                    <div>{{ langs["Stats"] }}</div>
                    <div>R{{ rd_id }}</div>
                    <div>{{ langs["TOT Rounds"] }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Driving Accuracy"] }}</div>
                    <div>{{ stats.str_driving_distance }}</div>
                    <div>{{ matchstats.str_driving_distance }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>GIR</div>
                    <div>{{ stats.str_gir }}</div>
                    <div>{{ matchstats.str_gir }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Putting Average"] }}</div>
                    <div>{{ stats.str_putts_pround }}</div>
                    <div>{{ matchstats.str_putts_pround }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Putts per GIR"] }}</div>
                    <div>{{ stats.str_putts_pgir }}</div>
                    <div>{{ matchstats.str_putts_pgir }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Stroking Average"] }}</div>
                    <div>{{ stats.str_stroke }}</div>
                    <div>{{ matchstats.str_stroke }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Saving Accuracy"] }}</div>
                    <div>{{ stats.str_scramble }}</div>
                    <div>{{ matchstats.str_scramble }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Sand Saves"] }}</div>
                    <div>{{ stats.str_sand_saves }}</div>
                    <div>{{ matchstats.str_sand_saves }}</div>
                </div>
                <div class="lf_stats_box_b">
                    <div>{{ langs["Average Driving Distance"] }}</div>
                    <div>{{ stats.str_driving_distance }}</div>
                    <div>{{ matchstats.str_driving_distance }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Ad from "../components/Ad.vue";
import axios from "../axios/request";
import axios1 from "../axios/request1";
import {
		wexinShare
	} from '@/assets/utils.js';
import * as echarts from "echarts";
import { getSystemLang, getLangAll } from "../lang";
//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

import { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
//这里就是更新后的路径，以前是import 'swiper/css'

//自动轮播的配置
export default {
    data() {
        return {
            langs: "",
            systemlang: "cn",
            banner: {},
            jiangjin: {},
            bannertr: {},
            year: new Date().getFullYear(),
            curyear: new Date().getFullYear(),
            player: [],
            match: [],
            roundsummary: [],
            roundtotal: [],
            holescore: [],
            holein: [],
            holeout: [],
            holescorePar: [],
            holescorechangePar: [],
            holescorechangeHole: [],
            piedata: [{ name: "", value: 0 }], //饼状图
            scorenum: [],
            countscorenum: 0,
            playername: "",
            playerscoreMax: 0, //各成绩出现的最多次数
            allscorenum: [], //平均雷达图数据
            stats: [],
            matchstats: [],
            mt_id: "",
            rd_id: "",
            pl_id: "",
            show1: "false",
            showloading: false,
            backshow: false,
			fenxiangcontent:{
				title:"wifiGOLF",
				desc:"@wifiGOLF数据支持",
				imgUrl:""
			},
        };
    },
    props: {},
    name: "Home",
    components: {
        Ad,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = (e) => {
            console.log("slide change123123123", e.activeIndex);
        };
        const autoplayOptions = {
            delay: 5000,
            disableOnInteraction: false,
            loop: false,
            pauseOnMouseEnter: true,
            reverseDirection: true,
        };
        return {
            onSwiper,
            onSlideChange,
            autoplayOptions,
            modules: [Pagination, Autoplay],
        };
    },
    created: function () {
        document.title = "成绩单 Scorecard";
        this.systemlang = getSystemLang();
        this.langs = getLangAll(this.systemlang);
        let geturl = window.location.href;
        let getqyinfo = geturl.split("?")[1]; //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99     截取到参数部分
        let getqys = new URLSearchParams("?" + getqyinfo); //将参数放在URLSearchParams函数中
        this.mt_id = getqys.get("mtid");
        this.rd_id = getqys.get("mt_round");
        this.pl_id = getqys.get("plid");
        setTimeout(function () {
            this.mt_id = getqys.get("mtid");
            this.rd_id = getqys.get("mt_round");
            this.pl_id = getqys.get("plid");
        }, 300);
        console.log(this.rd_id);
        this.getData(this.mt_id, this.rd_id, this.pl_id);
        var ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            wx.miniProgram.getEnv((res)=>{
                if (res.miniprogram) {
                    // alert("在小程序里");
                    this.backshow = false;
                } else {
                    // alert("不在小程序里");
                    this.backshow = true;
                }
            })
        }else{
            // alert('不在微信里');
            this.backshow = true;
        }
		var that=this;
		//setTimeout(function(){that.weixin()},1000);
    },
    methods: {
        getData: function (mt_id, rd_id, pl_id) {
			var that=this;
            var mt_id = mt_id;
            var rd_id = rd_id;
            var pl_id = pl_id;
            var lang = this.systemlang;
            this.rd_id = rd_id;
            this.show1 = true;
            this.showloading = false;
			
            axios("match/playerscore.json", {
                mt_id: mt_id,
                rd_id: rd_id,
                lang: lang,
                pl_id: pl_id,
                isless: 1,
            }).then((response) => {
                let data = response.data.data;
                console.log("ddddd", data);
                this.player = data.player;
                this.match = data.match;
                this.roundsummary = data.roundsummary;
                this.roundtotal = data.roundtotal;
                this.holescore = data.holescore;
                this.holescorechange = data.holescorechange;
                this.holein = data.holein;
                this.holeout = data.holeout;
                this.show1 = false;
				this.fenxiangcontent.title="成绩单 Scorecard - "+this.player['pl_cn_name'] + this.player['su_rank'],
				this.fenxiangcontent.desc=this.match["mt_cn_name"]
				
                for (let item of data.holescore) {
                    this.holescorePar.push(item.sc_to_par);
                }
                this.holescorechangeHole = [];
                this.holescorechangePar = [];
                for (let item of data.holescorechange) {
                    if (item == data.holescorechange[0]) {
                        this.holescorechangeHole.push(this.langs["Start"]);
                    } else {
                        this.holescorechangeHole.push(item.sc_ho_id);
                    }

                    this.holescorechangePar.push(item.sc_to_par);
                }
                this.playername = data.player.pl_cn_name;
                this.initCharts();
            });
			axios1('https://www.wifigolf.com/api5/index.php/v5.wifigolf.share.info.json',{otp:'info',mt_id:mt_id})
			.then((res)=>{
				this.fenxiangcontent.imgUrl=res.data.data.share_img;
				console.log('res.data.data.share_img',res.data.data.share_img,this.fenxiangcontent)
				setTimeout(function(){that.weixin()},1000);
			});
        },
        getDataLoading: function (mt_id, rd_id, pl_id) {
            var mt_id = mt_id;
            var rd_id = rd_id;
            var pl_id = pl_id;
            var lang = this.systemlang;
            this.rd_id = rd_id;
            this.showloading = true;
            this.show1 = true;
            axios("match/playerscore.json", {
                mt_id: mt_id,
                rd_id: rd_id,
                lang: lang,
                pl_id: pl_id,
            }).then((response) => {
                let data = response.data.data;
                console.log(data);
                this.player = data.player;
                this.match = data.match;
                this.roundsummary = data.roundsummary;
                this.roundtotal = data.roundtotal;
                this.holescore = data.holescore;
                this.holescorechange = data.holescorechange;
                this.holein = data.holein;
                this.holeout = data.holeout;
                this.scorenum = data.scorenum;
                this.allscorenum = data.allscorenum;
                this.playerscoreMax = data.playerscoreMax;
                this.stats = data.stats;
                this.matchstats = data.matchstats;
                this.show1 = false;

                for (let item in data.scorenum) {
                    if (item == "birdie") {
                        this.piedata[0] = { name: this.langs["Birdie"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    } else if (item == "borgey") {
                        this.piedata[1] = { name: this.langs["Bogey"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    } else if (item == "par") {
                        this.piedata[2] = { name: this.langs["Par"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    } else if (item == "doubleborgey") {
                        this.piedata[3] = { name: this.langs["D.Bogey"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    } else if (item == "eagle") {
                        this.piedata[4] = { name: this.langs["Eagle"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    } else if (item == "other") {
                        this.piedata[5] = { name: this.langs["Other"], value: data.scorenum[item] };
                        this.countscorenum += data.scorenum[item];
                    }
                }
                for (let item of data.holescore) {
                    this.holescorePar.push(item.sc_to_par);
                }
                this.holescorechangeHole = [];
                this.holescorechangePar = [];
                for (let item of data.holescorechange) {
                    if (item == data.holescorechange[0]) {
                        this.holescorechangeHole.push(this.langs["Start"]);
                    } else {
                        this.holescorechangeHole.push(item.sc_ho_id);
                    }

                    this.holescorechangePar.push(item.sc_to_par);
                }
                this.playername = data.player.pl_cn_name;
                this.initCharts1();
                this.initCharts();
                this.initCharts2();
            });
        },
        initCharts() {
            let myChart = echarts.init(this.$refs.chart);
            myChart.setOption({
                title: {
                    text: this.langs["Result Curve"],
                    left: "center",
                    textStyle: {
                        color: "#025287",
                        lineHeight: "24",
                        fontSize: "14",
                    },
                    padding: [20, 0, 0, 0],
                },
                grid: {
                    bottom: "22%",
                },
                xAxis: {
                    type: "category",
                    data: this.holescorechangeHole,
                    axisTick: {
                        show: true, // 是否显示坐标轴刻度。
                        interval: 1, // 坐标轴刻度的显示间隔，在类目轴中有效。不设置时默认同 axisLabel.interval 一样。设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推。
                        inside: true, // 默认值false。true 表示坐标轴刻度朝内，false 表示坐标轴刻度朝外
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        show: true, // 是否显示坐标轴刻度标签。
                        interval: 2, // 坐标轴刻度标签的显示间隔，在类目轴中有效。设置成 0 强制显示所有标签，如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
                        inside: false, // 默认值false。true 表示坐标轴刻度标签朝内，false 表示坐标轴刻度标签朝外
                        rotate: 0, // 刻度标签旋转的角度，旋转的角度从 -90 度到 90 度
                        margin: 20, // 刻度标签与轴线之间的距离
                        color: "#999", // 刻度标签文字的颜色。不设置就默认取 axisLine.lineStyle.color，即与轴线颜色一样
                    },
                },
                yAxis: {
                    inverse: true,
                    type: "value",
                    axisLabel: {
                        color: "#999", // 刻度标签文字的颜色。不设置就默认取 axisLine.lineStyle.color，即与轴线颜色一样
                    },
                    minInterval: 1,
                },
                series: [
                    {
                        data: this.holescorechangePar,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: "#025287",
                            width: 2,
                            type: "dashed",
                        },
                    },
                ],
            });
        },
        initCharts1() {
            let myChart1 = echarts.init(this.$refs.chart1);
            myChart1.setOption({
                title: {
                    text: "R" + this.rd_id + " " + this.langs["Result Distribution"],
                    left: "left",
                    textStyle: {
                        color: "#025287",
                        lineHeight: "24",
                        fontSize: "14",
                    },
                    padding: [15, 0, 0, 0],
                },
                grid: {
                    bottom: "12%",
                },
                tooltip: {
                    trigger: "item22",
                },
                legend: {
                    orient: "vertical",
                    x: "right",
                    y: "center",
                },
                color: ["#B72A47", "#025287", "#53565B", "#5808C3", "#E3B102", "#D7D7D7"],
                series: [
                    {
                        name: "",
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.piedata,
                        center: ["40%", "55%"],
                    },
                ],
            });
        },
        initCharts2() {
            let myChart2 = $echarts.init(this.$refs.chart2);
            myChart2.setOption({
                title: {
                    text: "",
                },
                legend: {
                    data: [this.langs["Winner"], this.playername],
                    orient: "vertical",
                    x: "left",
                    y: 30,
                },
                color: ["#d7d7d7", "#333"],
                radar: {
                    // shape: 'circle',
                    indicator: [
                        { name: this.langs["Eagle"], max: this.allscorenum.eagle },
                        { name: this.langs["Birdie"], max: this.allscorenum.birdie },
                        { name: this.langs["Par"], max: this.allscorenum.par },
                        { name: this.langs["Bogey"], max: this.allscorenum.borgey },
                        { name: this.langs["D.Bogey"], max: this.allscorenum.doubleborgey },
                        { name: this.langs["Other"], max: this.allscorenum.other },
                    ],
                    radius: 100,
                    axisLabel: {
                        color: "red",
                    },
                },
                series: [
                    {
                        name: "",
                        type: "radar",
                        lineStyle: {
                            width: 3,
                        },
                        data: [
                            {
                                value: [
                                    this.allscorenum.eagle,
                                    this.allscorenum.birdie,
                                    this.allscorenum.par,
                                    this.allscorenum.borgey,
                                    this.allscorenum.doubleborgey,
                                    this.allscorenum.other,
                                ],
                                name: thid.langs["Winner"],
                                symbol: "rect",
                                symbolSize: 6,
                                lineStyle: {
                                    type: "solid",
                                },
                                label: {
                                    show: true,
                                    formatter: [
                                        this.allscorenum.eagle,
                                        this.allscorenum.birdie,
                                        this.allscorenum.par,
                                        this.allscorenum.borgey,
                                        this.allscorenum.doubleborgey,
                                        this.allscorenum.other,
                                    ],
                                    color: "#999",
                                    position: "bottom",
                                },
                            },
                            {
                                value: [
                                    this.piedata[4].value,
                                    this.piedata[0].value,
                                    this.piedata[2].value,
                                    this.piedata[1].value,
                                    this.piedata[3].value,
                                    this.piedata[5].value,
                                ],
                                name: this.playername,
                            },
                        ],
                    },
                ],
            });
        },
		weixin() {
			var that=this;
			//请求微信配置参数接口（获取签名），由后台给接口给
			var urls = window.location.href.split('#')[0];
			//看后台请求接口是get/post
			axios1('https://www.wifigolf.com/home/mobile/index.php/Home/Rank/wxsdk.html?url='+encodeURIComponent(urls)).then(res => {
				
				if (res.status == 200) {
					//微信加签
					var obj = {
						appId: res.data.appId,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						timestamp: res.data.timestamp,
						jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
					};
					//分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
					//var navLang = localStorage.getItem('lang')||'cn';
					var url = window.location.href;
						//url = url.split('?')[0];   
						//url = window.location.href+'?&lang='+navLang+'&type='+that.tFlag;
						console.log('url---',url);
					// var shareWxLink = encodeURIComponent(url);加密
					let shareData = {
						title: that.fenxiangcontent.title, // 分享标题
						desc:that.fenxiangcontent.desc,
						//link: res.data.linkUrl,
						link: url,
						imgUrl: that.fenxiangcontent.imgUrl // 分享图标
					};
					//引用
					wexinShare(obj, shareData);
					// alert('获取sdk成功！');
				} else {
					alert('获取sdk参数失败！');
				}
			});
		},
    },
};
</script>

<style scoped="scoped">
.lf_new_top_box {
    display: flex;
    align-items: center;
    height: 40px;
    background: #005288;
    padding: 0 2.5%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 12px;
    z-index: 999;
}
.lf_match_top_box {
    width: 7rem;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 0.1rem;
    margin: 0.25rem auto 0;
    padding-bottom: 0.2rem;
}
.lf_playerscore_1 {
    display: flex;
    align-items: center;
    height: 0.64rem;
    margin: 0.2rem;
    font-weight: bold;
    position: relative;
}
.lf_playerscore_country_img {
    width: 0.46rem;
    height: 0.3rem;
    margin: 0 0.2rem;
}
.lf_playerscore_1 span {
    width: 1.2rem;
    height: 0.56rem;
    background: inherit;
    background-color: #ccc;
    border: none;
    border-radius: 0.2rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: "Arial Negreta", "Arial Normal", "Arial";
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_playerscore_1 .span_red {
    background-color: rgba(183, 42, 71, 1);
}
.lf_playerscore_1 .span_lan {
    background-color: #025287;
}
.lf_mt_name_c_box {
    width: 6.7rem;
    height: 1.1rem;
    background: inherit;
    background-color: rgba(240, 243, 246, 1);
    border: none;
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0.1rem;
}
.lf_mt_name_c {
    font-size: 0.26rem;
    color: #333;
    margin-top: 0.1rem;
}
.lf_mt_name_c_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_go_jcsp_box {
    width: 7rem;
    height: 0.8rem;
    background: -webkit-linear-gradient(
        172.90242415643deg,
        rgba(249, 94, 63, 1) -8%,
        rgba(233, 0, 76, 1) 29%
    );
    background: -moz-linear-gradient(
        -82.9024241564298deg,
        rgba(249, 94, 63, 1) -8%,
        rgba(233, 0, 76, 1) 29%
    );
    background: linear-gradient(
        -82.9024241564298deg,
        rgba(249, 94, 63, 1) -8%,
        rgba(233, 0, 76, 1) 29%
    );
    border: none;
    border-radius: 0.1rem;
    box-shadow: 0px 0px 0.1rem rgb(145 145 145 / 35%);
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
}
.lf_video_box {
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    margin: 0 0.28rem;
}
.lf_video_box img {
    width: 0.35rem;
    height: 0.33rem;
}
.lf_video_name {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-style: normal;
    font-size: 0.3rem;
    color: #ffffff;
    font-weight: bold;
}
.lf_video_name span {
    margin: 0 3px;
}
.lf_video_right {
    width: 0.3rem;
    height: 0.3rem;
    transform: rotate(90deg);
    position: absolute;
    right: 0.3rem;
}
.lf_round_C_T1 {
    width: 7rem;
    padding: 0.25rem 0.2rem;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 0.1rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.lf_round_C {
    width: 6.6rem;
    border-radius: 0.1rem;
    display: flex;
    align-items: center;
    margin: 0.1rem auto;
    position: relative;
}
.lf_round_C_bg1 {
    position: absolute;
    left: 0;
    top: 0.06rem;
    background-color: #025287;
    width: 100%;
    height: 0.6rem;
    z-index: 0;
    border-radius: 0.1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.lf_round_C_bg2 {
    position: absolute;
    left: 0;
    top: 0.66rem;
    background-color: #fff;
    width: 100%;
    height: 0.6rem;
    z-index: 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-top: none;
    border-radius: 0.1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.lf_round_C_1 {
    display: flex;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0.1rem;
    position: relative;
    max-width: 1.45rem;
}
.lf_round_C_1_select {
    border-radius: 0.2rem;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.349019607843137);
    z-index: 3;
}
.lf_round_C_1_select .lf_round_C_tit {
    font-size: 0.4rem;
    height: 0.66rem;
    background-color: #025287;
}
.lf_round_C_1_select .lf_round_C_con {
    font-size: 0.44rem;
    height: 0.66rem;
    background-color: #fff;
}
.lf_round_C_Tot {
    width: 1.8rem;
    height: 1.2rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: absolute;
    right: 0;
}
.lf_round_C_tit {
    font-family: ".萍方-简 中粗体", ".萍方-简 正规体", ".萍方-简";
    font-weight: 600;
    font-style: normal;
    font-size: 0.3rem;
    color: #ffffff;
    width: 100%;
    height: 0.58rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_round_C_con {
    font-family: ".萍方-简 中粗体", ".萍方-简 正规体", ".萍方-简";
    font-weight: 600;
    font-style: normal;
    font-size: 0.36rem;
    color: #333;
    width: 100%;
    height: 0.58rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_per_score_box {
}
.lf_per_score_box_tit {
    display: flex;
    height: 0.6rem;
    background-color: #025287;
    font-size: 0.24rem;
    color: #fff;
    font-weight: bold;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.lf_per_score_box_tit div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;
    box-sizing: border-box;
}
.lf_per_score_box_tit div:nth-of-type(1) {
    border: none;
}
.lf_per_score_box_par {
    display: flex;
    height: 0.6rem;
    background-color: #fff;
    font-size: 0.24rem;
    color: #fff;
    font-weight: bold;
}
.lf_per_score_box_par div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e3e3e3;
    box-sizing: border-box;
    color: #999;
    border-bottom: 1px solid #e3e3e3 !important;
}
.lf_per_score_box_par div:nth-of-type(1) {
    border: none;
    color: #333;
}
.lf_per_score_box_score {
    display: flex;
    height: 0.6rem;
    background-color: #fff;
    font-size: 0.24rem;
    color: #fff;
    font-weight: bold;
}
.lf_per_score_box_score div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e3e3e3;
    box-sizing: border-box;
}
.lf_per_score_box_score div:nth-of-type(1) {
    border: none;
    color: #333;
}
.lf_per_score_box_score div,
.lf_per_score_box_par div,
.lf_per_score_box_tit div {
    width: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.lf_per_score_box_score div:nth-of-type(1),
.lf_per_score_box_par div:nth-of-type(1),
.lf_per_score_box_tit div:nth-of-type(1) {
    width: 0.9rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
}
.lf_per_score_box_score div:nth-last-of-type(1),
.lf_per_score_box_par div:nth-last-of-type(1),
.lf_per_score_box_tit div:nth-last-of-type(1) {
    width: 0.62rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.lf_per_score_box_score div .lf_score_box_box {
    width: 0.5rem;
    height: 0.5rem;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    flex-shrink: 0;
}
.lf_per_score_box_score .lf_eagle {
    background: rgba(227, 177, 2, 1);
}
.lf_per_score_box_score .lf_birdie {
    background: rgba(183, 42, 71, 1);
}
.lf_per_score_box_score .lf_par {
    background: rgba(83, 86, 91, 1);
}
.lf_per_score_box_score .lf_borgey {
    background: rgba(2, 82, 135, 1);
}
.lf_per_score_box_score .lf_doubleborgey {
    background: rgba(88, 8, 195, 1);
}
.lf_stats_content {
    display: flex;
    flex-direction: column;
    border-radius: 0.1rem;
    overflow: hidden;
}
.lf_stats_content .lf_stats_tit_t {
    background-color: #f9f9f9;
}
.lf_stats_content .lf_stats_box_b {
    background-color: #fff;
}
.lf_stats_content .lf_stats_box_b,
.lf_stats_content .lf_stats_tit_t {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}
.lf_stats_content .lf_stats_tit_t div {
    display: flex;
    align-items: center;
    height: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    font-style: normal;
    font-size: 0.3rem;
    color: #333;
}
.lf_stats_content .lf_stats_box_b div {
    display: flex;
    align-items: center;
    height: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    font-style: normal;
    font-size: 0.28rem;
    color: #333;
}
.lf_stats_content .lf_stats_box_b div:nth-of-type(1),
.lf_stats_content .lf_stats_tit_t div:nth-of-type(1) {
    width: 3.2rem;
    flex-shrink: 0;
}
.lf_stats_content .lf_stats_box_b div:nth-of-type(2),
.lf_stats_content .lf_stats_tit_t div:nth-of-type(2) {
    width: 1.9rem;
    flex-shrink: 0;
}
.lf_stats_content .lf_stats_box_b div:nth-of-type(3),
.lf_stats_content .lf_stats_tit_t div:nth-of-type(3) {
    width: 1.9rem;
    flex-shrink: 0;
}
.lf_stats_content .lf_stats_box_b div:nth-of-type(1) {
    font-weight: 400;
}
.lf_stats_content .lf_stats_box_b:nth-last-of-type(1) {
    border-bottom: none;
}
.lf_pross_box {
    width: 6.5rem;
    height: 0.2rem;
    border-radius: 0.1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0.1rem auto;
    position: relative;
    top: -0.3rem;
}
.lf_pross_box div {
    height: 100%;
}
.lf_pross_box div:nth-of-type(1) {
    background: rgba(227, 177, 2, 1);
}
.lf_pross_box div:nth-of-type(2) {
    background: rgba(183, 42, 71, 1);
}
.lf_pross_box div:nth-of-type(3) {
    background: rgba(83, 86, 91, 1);
}
.lf_pross_box div:nth-of-type(4) {
    background: rgba(2, 82, 135, 1);
}
.lf_pross_box div:nth-of-type(5) {
    background: rgba(88, 8, 195, 1);
}
.lf_pross_box div:nth-of-type(6) {
    background: #d7d7d7;
}
.lf_pross_box1 {
    height: 0.3rem;
    justify-content: space-evenly;
}
.lf_pross_box1 div {
    width: auto;
    background: none !important;
    font-size: 0.22rem;
}
.lf_scorecc_box {
    border-radius: 0.1rem;
    overflow: hidden;
    margin: 0.35rem auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 6.5rem;
    background: #fff;
    padding: 0.35rem 0 0;
}
.lf_scorecc_box_1 {
    background-color: #025287;
    color: #fff;
    font-weight: bold;
    width: 6.5rem;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
}
.lf_scorecc_box_1 div {
    width: 33.33%;
    height: 0.9rem;
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_scorecc_box_2 {
    display: flex;
    width: 6.5rem;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    background-color: rgba(249, 249, 249, 1);
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
    margin-bottom: 0.15rem;
}
.lf_scorecc_box_2 div {
    width: 33.33%;
    height: 0.9rem;
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.lf_scorecc_box_2 div span:nth-of-type(1) {
    font-size: 0.36rem;
    color: #333;
    font-weight: bold;
}
.lf_scorecc_box_2 div span:nth-of-type(2) {
    font-size: 0.3rem;
    color: #999;
}
.show1 {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.show1 img {
    width: 3rem;
    height: 3rem;
}
.lf_back_h {
    width: 1.2rem;
    height: 0.5rem;
    display: flex;
    font-size: 0.26rem;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 5px;
    position: absolute;
    right: 0.2rem;
}
.lf_go_more {
    font-size: 0.24rem;
    color: #4b5d9d;
    text-align: center;
    width: 100%;
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lf_country_text {
    font-weight: 700;
    color: #025287 !important;
    font-size: 0.28rem;
    margin-right: 0.1rem;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-top: 2px;
}
@media screen and (max-width: 768px) {
}
</style>
