<template>
    <div  class="lf_Top_1">
        <el-row>
            <el-col :xs="24" :sm="8" :md="8">
                <div class="lf_new_top_">
                    <div class="lf_new_top_box">
                        <a href="javascript:window.history.back();" target="_self"><img src="../assets/images/jt_left.png" class="toBack"></a>
                        <img src="../assets/images/logo.png" class="logo" style="height: 22px;width: 82px;" />
                        <div style="color: #fff;margin: 10px 0 0 1%;">让计算更简单</div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                banner:{},
                cj:{}

            };
        },
        name: 'navs',
        props: {
            msg: String,

        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);

            //获取banner
            // axios('news/banner/nav.json',{abs:'NAV_RIGHT'})
            // .then((res)=>{
            //     let data = res.data.data;
            //     this.banner = data;
            // });

            //获取及时成绩
            //获取banner
            // axios('matchs/match/curmatch.json')
            // .then((res)=>{
            //     let data = res.data.data;
            //     this.cj = data;
            // });

        },


    }
</script>

<style scoped>
    .lf_new_top_{
    	width: 100%;
    	height:40px;
    }
    .lf_new_top_box{
    	display: flex;
    	align-items: center;
    	height: 40px;
    	background: #005288;
    	padding: 0 2.5%;
    	position: fixed;
    	left: 0;
    	top:0;
    	width: 100%;
    	font-size: 12px;
    	z-index: 999;
    }
    .lf_new_top_box .toBack{
    	width: 15px;
    	height: 22px;
    	margin-right: 10px;
    	margin-top: 4px;
    }
	@media screen and (max-width: 768px) {
		
	}
</style>
