// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import playerscore from '../views/Per.vue'
import Home from '../views/Home.vue'
import rank from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rank',
    name: 'rank',
    component: rank
  },
  {
    path: '/playerscore',
    name: 'playerscore',
    component: playerscore
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  
  let flag = to.path.toLowerCase();
  let query = to.query;


  if(query.lang == 'cn')
  {
    localStorage.setItem('lang',query.lang)
  }
  else if(query.lang == 'en')
  {
    localStorage.setItem('lang',query.lang)
  }


  if(flag == '/' && !query.lang)
  {
    // console.log(navigator.language,'langflag');
    let navLang = navigator.language.substring(0, 2)
    var langflag = 'cn';
    if (navLang == 'en') {
      langflag = 'en';
    } else {
      langflag = 'cn'
    }
    console.log(langflag);
    // return false;
    localStorage.setItem('lang',langflag)
    next({ path: '/', query: {lang:langflag}})
  }

  // if(flag=='/score/posc' && to.query.check != '1')
  // {
  //   let query = to.query;
  //   query.path = flag;
  //   next({ path: '/go', query: query})
  // }
  else if(flag == '/score/index' && to.query.check != '1' && to.query.mtid <= 0 && to.query.shouchange !=1) //跳转到排名首页
  {
    let query = to.query;
    query.path = flag;
    next({ path: '/go', query: query})
  }
  else if(flag == '/home' && to.query.check != '1'  && to.query.mtid <= 0 )   //跳转到分组
  {
    let query = to.query;
    query.path = flag;
    next({ path: '/go', query: query})
  }
  else
  {
    next();
  }
  
})

export default router
