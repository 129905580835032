/* eslint-disable */
function getSystemLang() {
	var navLang = 'cn';
	// var url = window.location.href; //获取当前url
	// var cs = url.split('?')[1]; //获取?之后的参数字符串
	// var cs_arr = cs.split('&'); //参数字符串分割为数组
	// var cs = {};
	// for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
	// 	cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
	// }
	// navLang = cs.lang;
	// if (navLang == null) {
	// 	navLang = localStorage.getItem('lang');
	// }else if(localStorage.getItem('lang')==null){
	// 	navLang = navigator.language.substring(0, 2);
	// }
	navLang = navigator.language.substring(0, 2);

	if (navLang == 'en') {
		systemlang = 'en';
	} else if (navigator.language == 'zh-TW' || navLang == 'tw') {
		systemlang = 'tw';
	} else {
		systemlang = 'cn';
	}
	// console.log(systemlang)
  	return systemlang;
}

function getLangAll(systemlang) {
  var tt = {};
  for (var key in langarr) {tt[key] = langarr[key][systemlang];
  }
  return tt;
}

var langarr = {
  //----------------界面显示文字 start------------------
'login':{'cn':'登录','en':'Login'} ,
'text_pl_country':{'cn':'国籍','en':'Country/Region'} ,
'text_player':{'cn':'球员','en':'Player','tw':'球員'} ,
'text_news':{'cn':'新闻','en':'News'} ,
'text_ranking':{'cn':'排名','en':'Ranking'} ,
'text_price':{'cn':'奖金','en':'Prize'} ,
'Numberofentries':{'cn':'参赛场','en':'Entered'} ,
'Quit':{'cn':'退出','en':'Quit'} ,
'Username':{'cn':'账号','en':'Username'} ,
'Inputyourusername':{'cn':'请输入账号','en':'Input your username'} ,
'Password':{'cn':'密码','en':'Password'} ,
'Inputyourpassword':{'cn':'请输入密码','en':'Input your password'} ,
'Home':{'cn':'首页','en':'Home'} ,
'TournamentsSchedule':{'cn':'赛程','en':'Schedule'} ,
'News':{'cn':'新闻','en':'News'} ,
'Live':{'cn':'直播','en':'Live'} ,
'Stats':{'cn':'技术统计','en':'Stats'} ,
'Leaderboard':{'cn':'排行榜','en':'Leaderboard'} ,
'Leaderboard1':{'cn':'成绩','en':'Leaderboard'} ,
'PointsRankings':{'cn':'排行榜','en':'Rankings'} ,
'RealtimeScore':{'cn':'即时成绩','en':'Real-time Score'} ,
'OrderofMerit':{'cn':'女子中巡奖金榜','en':'Order of Merit'} ,
'':{'cn':'新闻精选','en':'Selected News'} ,
'More':{'cn':'查看更多','en':'More'} ,
'':{'cn':'巡回赛合作伙伴','en':"Tour's Partner"} ,
'Enterprise':{'cn':'企业介绍','en':'About'} ,
'':{'cn':'巡回赛供应商','en':"Tour's Supplier"} ,
'Field':{'cn':'参赛球员','en':'Field'} ,
'':{'cn':'请输入球员名称','en':"Please Input Player's Name"} ,
'Inquiry':{'cn':'查询','en':'Inquiry'} ,
'Age':{'cn':'年纪','en':'Age'} ,
'Height':{'cn':'身高','en':'Height'} ,
'YearofTurningProfessional':{'cn':'转职时间','en':'Year of Turning Professional'} ,
'BestAwards':{'cn':'最好成绩','en':'Best Awards'} ,
'Yards/Pars':{'cn':'比赛码数/标准杆','en':'Yards/Pars'} ,
'':{'cn':'比赛形式','en':'Format'} ,
'':{'cn':'门票信息','en':'Tickets Info'} ,
'':{'cn':'赛事历史','en':'History'} ,
'':{'cn':'往年冠军','en':'Previous Champions'} ,
'Year':{'cn':'年份','en':'Year'} ,
'Champion':{'cn':'冠军球员','en':'Champion'} ,
'RoundScore':{'cn':'每轮成绩','en':'Round Score'} ,
'TotalScore':{'cn':'总杆数','en':'Total Score'} ,
'Events':{'cn':'赛事','en':'Events'} ,
'Period':{'cn':'时间','en':'Period'} ,
'WinstoPar':{'cn':'夺冠成绩','en':'Wins to Par'} ,
'':{'cn':'巡回赛新闻','en':'Tour News'} ,
'':{'cn':'头条新闻','en':'Highlights'} ,
'Order':{'cn':'序号','en':'Order'} ,
'SignUp':{'cn':'参赛报名','en':'Sign Up'} ,
'FactSheet':{'cn':'赛事信息','en':'Fact Sheet'} ,
'ydbxz':{'cn':'预定表下载','en':'Reservation table download'} ,
'none':{'cn':'无信息','en':'None'} ,
'ShGk':{'cn':'赛事概况','en':'Event Overview'} ,
'FactSheet1':{'cn':'赛事信息','en':'Tour Info'} ,
'PleaseSelect':{'cn':'请选择','en':'Please Select'} ,
'SignedUpEvents':{'cn':'报名赛站','en':'Signed Up Events'} ,
'SignUp':{'cn':'正赛报名','en':'Sign Up'} ,
'Time':{'cn':'赛事时间','en':'Time'} ,
'Venue':{'cn':'赛事地点','en':'Venue'} ,
'EntryFee':{'cn':'参  赛  费','en':'Entry Fee'} ,
'EntryFee1':{'cn':'正赛参赛费','en':'Entry Fee'} ,
'EntryDeadline':{'cn':'报名截止','en':'Entry Deadline'} ,
'ChineseName':{'cn':'中  文  名','en':'Chinese Name'} ,
'EnglishName':{'cn':'英  文  名','en':'English Name'} ,
'Nationality':{'cn':'所属国籍','en':'Nationality'} ,
'IDNumber':{'cn':'身份证号','en':'ID Number'} ,
'MobileNumber':{'cn':'手机号码','en':'Mobile Number'} ,
'EmailAddress':{'cn':'电子邮箱','en':'Email Address'} ,
'Identity':{'cn':'身       份','en':'Identity'} ,
'Attribution':{'cn':'代表球会/企业','en':'Attribution'} ,
'Bank':{'cn':'银       行','en':'Bank'} ,
'NameofAccountBank':{'cn':'开户行名称','en':'Name of Account Bank'} ,
'BankAccountNumber':{'cn':'银行账号','en':'Bank Account Number'} ,
'InvoiceTitle':{'cn':'发票名头','en':'Invoice Title'} ,
'TaxIdentificationNumber':{'cn':'税       号','en':'Tax Identification Number'} ,
'CGAMembershipNumber':{'cn':'中高协会员','en':'CGA Membership Number'} ,
'RelatedService':{'cn':'相关服务','en':'Related Service'} ,
'Hotel':{'cn':'酒        店','en':'Hotel'} ,
'OfficialHotel':{'cn':'入住官方酒店','en':'Official Hotel'} ,
'OfficialHotel1':{'cn':'官方酒店','en':'Official Hotel'} ,
'SelfArrangement':{'cn':'自己安排','en':'Self Arrangement'} ,
'Caddie':{'cn':'球        童','en':'Caddie'} ,
'CourseProvided':{'cn':'球会安排','en':'Club Caddie'} ,
'SelfProvided':{'cn':'自带球童','en':'Own Caddie'} ,
'Submit':{'cn':'提交','en':'Submit'} ,
'Theorderhasbeensubmitted':{'cn':'您已经提交订单','en':'The order has been submitted'} ,
'':{'cn':'参赛报名名单','en':'Field List'} ,
'AllOrders':{'cn':'全部订单','en':'All Orders'} ,
'WaitingforApproval':{'cn':'等待审核','en':'Waiting for Approval'} ,
'WaitingforPayment':{'cn':'等待支付','en':'Waiting for Payment'} ,
'Completed':{'cn':'已完成','en':'Completed'} ,
'Attention':{'cn':'特别提醒','en':'Attention'} ,
'OrderNumber':{'cn':'订单号','en':'Order Number'} ,
'EventName':{'cn':'赛事名称','en':'Event Name'} ,
'EventNameService':{'cn':'收费项目','en':'Pay Service'} ,
'AmountYuan':{'cn':'金额(元)','en':'Amount (Yuan)'} ,
'StatusofOrder':{'cn':'订单状态','en':'Status of Order'} ,
'EventPeriod':{'cn':'比赛时间','en':'Event Period'} ,
'DeadlinePaymentDate':{'cn':'最晚支付时间','en':'Deadline Payment Date'} ,
'OrderTime':{'cn':'订单时间','en':'Order Time'} ,
'Operation':{'cn':'操作','en':'Operation'} ,
'PrintOrder':{'cn':'打印订单','en':'Print Order'} ,
'':{'cn':'订单下载打印','en':'Order Download and Print'} ,
'':{'cn':'下载中.....','en':'Downloading…'} ,
'RefundConfirm':{'cn':'确认需要退款操作','en':'Refund Confirm'} ,
'OperationConfirm':{'cn':'操作确认','en':'Operation Confirm'} ,
'Confirm':{'cn':'确定','en':'Confirm'} ,
'Cancel':{'cn':'取消','en':'Cancel'} ,
'':{'cn':'提交成功','en':'Submittted Successfully'} ,
'':{'cn':'微信扫码付款','en':'Wechat QR Code Scan Payment'} ,
'PaidSuccessfully':{'cn':'支付成功','en':'Paid Successfully '} ,
'SelecttheMethodofPayment':{'cn':'请选择支付方式','en':'Select the Method of Payment'} ,
'':{'cn':'微信','en':'Wechat'} ,
'':{'cn':'支付宝','en':'Alipay'} ,
'PickupDropoffService':{'cn':'接送机预定','en':'Pick-up/Drop-off Service'} ,
'PickupService':{'cn':'接机预定','en':'Pick-up Service'} ,
'DropoffService':{'cn':'送机预定','en':'Drop-off Service'} ,
'NameoftheEntriedEvent':{'cn':'报名赛事名称','en':'Name of the Entried Event'} ,
'EventPeriod':{'cn':'比赛时间','en':'Event Period'} ,
'PickupInfo':{'cn':'接机信息填写','en':'Pick-up Info'} ,
'DropoffInfo':{'cn':'送机机信息填写','en':'Drop-off Info'} ,
'NoneedforPickup':{'cn':'无需接机','en':'No need for Pick-up'} ,
'NoneedforDropoff':{'cn':'无需送机','en':'No need for Drop-off'} ,
'CourseIntroduction':{'cn':'球场介绍','en':'Course'} ,
'CourseName':{'cn':'球场名称','en':'Course Name'} ,
'CourseLocation':{'cn':'球场地点','en':'Course Location'} ,
'TotalLength':{'cn':'球场总长度','en':'Total Length'} ,
'CourseWebsiteLink':{'cn':'球场官网友情链接','en':'Course Website Link'} ,
'':{'cn':'码','en':'Yard'} ,
'':{'cn':'球场整体地图','en':'Course Layout'} ,
'Hole':{'cn':'洞号','en':'Hole'} ,
'Yards':{'cn':'码数','en':'Yards'} ,
'Description':{'cn':'文字描述','en':'Description'} ,
'Pictures':{'cn':'图片展示','en':'Pictures'} ,
'TeeTimes':{'cn':'分组表','en':'Tee Times'} ,
'SubjecttotheLocalTimeZone':{'cn':'海外赛事以当地时区为准','en':'Subject to the Local Time Zone'} ,
'Gruop':{'cn':'组','en':'Gruop'} ,
'Start':{'cn':'开球时间','en':'Start'} ,
'Tee':{'cn':'发球台','en':'Tee'} ,
'':{'cn':'球员成绩表','en':'Results'} ,
'Amateur':{'cn':'(A) 业余选手','en':'(A) Amateur'} ,
'StartfromBacknine':{'cn':'*  选手从后九洞开球','en':'* Start from Back nine'} ,
'Confirmed':{'cn':'选手成绩已确认','en':'Confirmed'} ,
'Unconfirmed':{'cn':'选手成绩未确认','en':'Unconfirmed'} ,
'WoninPlayoff':{'cn':'延长赛获胜选手','en':'Won in Playoff'} ,
'DownloadPDF':{'cn':'PDF下载','en':'Download PDF'} ,
'DownloadExcel':{'cn':'Excel下载','en':'Download Excel'} ,
'Today':{'cn':'本轮','en':'Today'} ,
'Score':{'cn':'杆数','en':'Score'} ,
'Putts':{'cn':'推杆','en':'Putts'} ,
'Drive':{'cn':'发球','en':'Drive'} ,
'Accuracy':{'cn':'准确率','en':'Accuracy'} ,
'Bunker':{'cn':'沙坑','en':'Bunker'} ,
'Save':{'cn':'救球率','en':'Save'} ,
'Average':{'cn':'平均','en':'Average'} ,
'DrivingDistance':{'cn':'开球距离','en':'Driving Distance'} ,
'Par':{'cn':'标准杆','en':'Par'} ,
'GIR':{'cn':'上果岭率','en':'GIR'} ,
'PerRound':{'cn':'每轮','en':'Per Round'} ,
'OnePutt':{'cn':'一推杆','en':'One-Putt'} ,
'GIR1':{'cn':'标准杆上果岭','en':'GIR'} ,
'AveragePutting':{'cn':'平均推杆','en':'Average Putting'} ,
'Scrambling':{'cn':'未标准杆上果岭','en':'Scrambling'} ,
'':{'cn':'本轮杆数','en':'Round Score'} ,
'':{'cn':'本轮推杆','en':'Round Putts'} ,
'':{'cn':'开球准确率','en':'Driving Accuracy'} ,
'':{'cn':'沙坑救球率','en':'Sand Save'} ,
'':{'cn':'平均开球距离','en':'Average Driving Distance'} ,
'':{'cn':'标准杆上果岭率','en':'Green in Regulation(GIR)'} ,
'':{'cn':'每轮一推杆','en':'One-Putt Per Round'} ,
'':{'cn':'标准杆上果岭平均推杆','en':'Putts Per GIR'} ,
'':{'cn':'未标准杆上果岭救球率','en':'Scrambling'} ,
'EntryOrder':{'cn':'参赛报名订单','en':'Entry Order'} ,
'OtherOrder':{'cn':'其他订单','en':'Other Order'} ,
'':{'cn':'接送机预定','en':'Pick-up/Drop-off Service'} ,
'TotalPurse':{'cn':'赛事总奖金','en':'Total Purse'} ,
'WatchLiveStream':{'cn':'直播中','en':'LIVE'} ,
'TourInfo':{'cn':'巡回赛信息','en':'Tour Info'} ,
'Sh':{'cn':'赛事','en':'Match'} ,
'Score':{'cn':'成绩','en':'Score','tw':'成绩'} ,
'News':{'cn':'新闻','en':'News'} ,
'Pictures':{'cn':'图片','en':'Pictures'} ,
'Video':{'cn':'视频','en':'Video'} ,
'':{'cn':'精彩新闻','en':'News Highlights'} ,
'PleaseSelecttheYear':{'cn':'请选择年份','en':'Please Select the Year'} ,
'PleaseSelecttheEvent':{'cn':'请选择赛事','en':'Please Select the Event'} ,
'PleaseInput':{'cn':'请输入球员名称','en':"Please Input Player's Name"},
'':{'cn':'头条新闻','en':'Headlines'} ,
'':{'cn':'巡回赛新闻','en':'Tour News'} ,
'':{'cn':'赛站新闻','en':'Event News'},
'Rounds1':{'cn':'统计场','en':'of Events'} ,
'SGTotal':{'cn':'综合','en':'SG Total'} ,
'':{'cn':'女子中巡奖金榜','en':'Order of Merit'} ,
'Rounds':{'cn':'参赛场','en':'Entered'} ,
'':{'cn':'劳力士世界排名','en':"Rolex Women's World Golf Rankings"} ,
'':{'cn':'中国女子高尔夫运动员排名','en':"CGA Women's Golf Rankings"} ,
'ThePrevious':{'cn':'上一场','en':'The Previous'} ,
'Ongoing':{'cn':'进行中','en':'Ongoing…'} ,
'TheNext':{'cn':'下一场','en':'The Next'} ,
'EventNameLocation':{'cn':'赛事名称/赛事地点','en':'Event Name/Location'} ,
'DefendingChampionPrize1':{'cn':'卫冕冠军/冠军','en':'Defending Champion/Champion'} ,
'DefendingChampionPrize2':{'cn':'赛事总奖金','en':'Prize'} ,
'':{'cn':'已完成赛事','en':'Events Completed '} ,
'text_ren':{'cn':'人','en':''} ,
'Averageintegral':{'cn':'平均积分','en':'Average'} ,
'text_di':{'cn':'第','en':'Round'} ,
'text_lun':{'cn':'轮','en':''} ,
'Applyforrefund':{'cn':'申请退款','en':'Apply for refund'} ,
'resstpassword':{'cn':'忘记密码','en':'Forgot Password'} ,
'enteraccount':{'cn':'请先输入账号！','en':'Please enter the account number first!'},
"ts1":{'cn':'密码错误，是否需要重置','en':'Password is wrong;Do you want to reset the password?'},
"ts2":{'cn':'新密码已发送至','en':'New password has been sent to '},
"ts3":{'cn':'邮箱','en':''},
"ts4":{'cn':'您的邮箱不存在,请联系管理员','en':'Your mailbox does not exist. Please contact the administrator'},
"ts5":{'cn':'发送失败','en':'Fail in send'},
"ts6":{'cn':"系统将重置密码，发送至您的邮箱",'en':'Do you want to send the new password to '},
"ts7":{'cn':"，确定重置吗？",'en':'?'},
'text_ChangePassword':{'cn':'修改密码','en':'Change Password'} ,
'text_Newpassword':{'cn':'输入新密码','en':'New password'} ,
'text_Newpasswordagain':{'cn':'再次输入新密码','en':'New password again'} ,
'text_signup':{'cn':'报名','en':'sign up'} ,
'oPayment':{'cn':'支付','en':'Pay'} ,
'oCancel':{'cn':'取消订单','en':'cancel'} ,
'text_Signupinfo':{'cn':'报名信息','en':'Signup info'} ,
'text_Playersname':{'cn':'球员姓名','en':"Player's name"} ,
'text_IDtype':{'cn':'证件类型','en':'ID type'} ,
'text_IDnumber':{'cn':'证件号码','en':'ID number'} ,
'text_Gender':{'cn':'性别','en':'Gender'} ,
'text_Pro':{'cn':'职业','en':'Pro'} ,
'text_Am':{'cn':'业余','en':'Am'} ,
'text_Nationality':{'cn':'国籍','en':'Nationality'} ,
'text_Birthdate':{'cn':'出生日期','en':'Birth date'} ,
'text_date':{'cn':'日期','en':'Date'} ,
'text_Selectdate':{'cn':'选择日期','en':'Select date'} ,
'text_Relationtype':{'cn':'与被监护人关系','en':'Relation type'} ,
'text_Guardiansname':{'cn':'监护人姓名','en':"Guardian's name"} ,
'text_Contactinfo':{'cn':'监护人联系方式','en':'Contact info'} ,
'text_Countrycode':{'cn':'国际区号','en':'Country code'} ,
'text_IDofWorldWomensgolf':{'cn':'世界女子高尔夫排名ID （四位数字）','en':"ID of World Women's golf rankings (4 digits)"} ,
'text_Airportpickupinfo':{'cn':'接机信息','en':'Airport pickup info'} ,
'text_Airportdropoffinfo':{'cn':'送机信息','en':'Airport drop-off info'} ,
'text_Noticearrangementsforpickup':{'cn':'通知：接送机安排将于近期公布，望周知。','en':'Notice: arrangements for pick-up and drop-off will be announced in the near future.'} ,
'text_Ihavereadtheevent':{'cn':'我已阅读赛事相关信息并清楚报名费支付成功后不予退还','en':' I have read the event information and know that the entry fee is non-refundable after successful payment'} ,
'text_Submitandpayforthesignup':{'cn':'提交并支付报名','en':'Submit and pay for the signup'} ,
'text_Signup':{'cn':'参赛报名','en':'Sign up'} ,
'text_Signupinfo1':{'cn':'报名查询','en':'Signup info'} ,
'WaitingforPaymentN':{'cn':'提交支付','en':'Pay'} ,
'text_Submit':{'cn':'确认查询','en':'Submit'} ,
'text_back':{'cn':'返回','en':'Back'},
'WaitingforPaymentN':{'cn':'提交支付','en':'Pay'},
'notsame':{'cn':'两次输入的密码不相同','en':'passwords different'},
'nve':{'cn':'女','en':'girl'},
'look':{'cn':'查看','en':'Details'},
'PersonalScore':{'cn':'个人成绩','en':'Personal Score'},
'TeamScore':{'cn':'团队成绩','en':'Team Score'},
'text_IDtype_select':{'cn':'请选择证件类型','en':'Please select a certificate type'} ,
'cardpayTs':{'cn':'等待付款，请勿关闭此页面，关闭后无法接收支付状态','en':'Please do not close this page while waiting for payment. Payment status cannot be accepted after closing'},

'MatchTsnr_c':{'cn':'没有进行中比赛','en':'Unprocessed Competitions'},
'MatchTsnr_o':{'cn':'暂无已完结的比赛','en':'No Completed Matches'},
'MatchTsnr_f':{'cn':'暂无未来比赛','en':'No Future Competitions'},
'cj_zd':{'cn':'逐洞成绩','en':'Hole by hole'},
'cj_lxb':{'cn':'领先榜','en':'Leaderboard'},
'cj_tm':{'cn':'团队成绩','en':'Team Score'},
'tj_date':{'cn':'统计至','en':'RANKINGS AS OF'},
'gerensai':{'cn':'个人赛','en':'Personal','tw':'個人賽'},
'tuanduisai':{'cn':'团队赛','en':'5','Team':'團隊賽'},
'BGS':{'cn':'个人总杆','en':'Stroke Play','tw':'個人總杆'},
'jinggan':{'cn':'个人净杆','en':'Net Stroke Play','tw':'個人净杆'},
'DFS':{'cn':'个人定分','en':'Stableford','tw':'個人定分'},
'BDS':{'cn':'个人比洞','en':'Match Play','tw':'個人比洞'},
'QNCJ':{'cn':'团队总杆','en':'Team Stroke Play','tw':'團體總杆'},
'TNET':{'cn':'团队净杆','en':'Team Net Stroke Play','tw':'團體净杆'},
'TNET-NNP':{'cn':'团队自报差点','en':'Team Handicap Play','tw':'團體自報差點'},
'TDDF':{'cn':'团队定分','en':'Team Stableford','tw':'團體定分'},
'NNP':{'cn':'新新贝利亚','en':'New Peoria','tw':'新新貝利亞'},
'ZBCD':{'cn':'自报差点','en':'Handicap Play','tw':'自報差點'},
'NNP_ZBCD':{'cn':'自报差点','en':'Handicap Play','tw':'自報差點'},
'SRSQ':{'cn':'四人四球','en':'Four-balls','tw':'四人四球'},
'SRLQ':{'cn':'四人两球','en':'Foursomes','tw':'四人兩球'},
'LRLQ':{'cn':'单人对抗','en':'Singles','tw':'單人對抗'},
'sqw':{'cn':'双旗位','en':'Power Play','tw':'雙旗位'},
'qn':{'cn':'取前','en':'Top ','tw':'取前'},
'qzh':{'cn':'取最好','en':'Best score','tw':'取最好'},
'qgh':{'cn':'取杆和','en':'Gross score per Hole','tw':'取杆和'},
'zdqn':{'cn':'逐洞取前','en':'Top X per Hole','tw':'逐洞取前'},
'zdqzh':{'cn':'逐洞取最好','en':'Best Ball','tw':'逐洞取最好'},
'zdqgh':{'cn':'逐洞取杆和','en':'Gross score per Hole','tw':'逐洞取杆和'},
'playoff':{'cn':'延长赛','en':'PLAYOFF','tw':'延長賽'},
'curround':{'cn':'当轮','en':'Tod','tw':'當輪'},
'fin':{'cn':'完成','en':'Thru','tw':'完成'},
'jingganscore':{'cn':'净杆','en':'Net','tw':'淨桿'},
'cd':{'cn':'差点','en':'HDCP','tw':'差點'},
'total':{'cn':'总杆','en':'Total','tw':'总桿'},
'R1':{'cn':'一轮','en':'R1','tw':'一轮'},
'R2':{'cn':'二轮','en':'R2','tw':'二轮'},
'R3':{'cn':'三轮','en':'R3','tw':'三轮'},
'R4':{'cn':'四轮','en':'R4','tw':'四轮'},
'R5':{'cn':'五轮','en':'R5','tw':'五轮'},
'R6':{'cn':'六轮','en':'R6','tw':'六轮'},
'Pos.':{'cn':'第','en':'Pos.','tw':'第'} ,
'ming':{'cn':'名','en':'','tw':'名'} ,
'Back':{'cn':'返回','en':'Back','tw':'返回'} ,
'Highlights':{'cn':'精彩瞬间','en':'Highlights','tw':'精彩瞬間'} ,
'Par':{'cn':'标准杆','en':'Par','tw':'標準杆'} ,
'Scorechengji':{'cn':'成绩','en':'Score','tw':'成績'} ,
'View More':{'cn':'点击查看 更多统计数据','en':'View More','tw':'點擊查看 更多統計數據'} ,
'ge':{'cn':'个','en':'','tw':'個'} ,
'Eagle':{'cn':'老鹰','en':'Eagle','tw':'老鷹'} ,
'Birdie':{'cn':'小鸟','en':'Birdie','tw':'小鳥'} ,
'Par':{'cn':'帕','en':'Par','tw':'帕'} ,
'Bogey':{'cn':'柏忌','en':'Bogey','tw':'柏忌'} ,
'D.Bogey':{'cn':'双柏忌','en':'D.Bogey','tw':'雙柏忌'} ,
'Other':{'cn':'其他','en':'Other','tw':'其他'} ,
'Par 3 Scores':{'cn':'3杆洞成绩','en':'Par 3 Scores','tw':'3杆洞成績'} ,
'Par 4 Scores':{'cn':'4杆洞成绩','en':'Par 4 Scores','tw':'4杆洞成績'} ,
'Par 5 Scores':{'cn':'5杆洞成绩','en':'Par 5 Scores','tw':'5杆洞成績'} ,
'Front 9':{'cn':'前九洞成绩','en':'Front 9','tw':'前九洞成績'} ,
'Back 9':{'cn':'后九洞成绩','en':'Back 9','tw':'後九洞成績'} ,
'TOTAL':{'cn':'总成绩','en':'TOTAL','tw':'總成績'} ,
'TOT Rounds':{'cn':'整场比赛','en':'TOT Rounds','tw':'整場比賽'} ,
'Performance':{'cn':'各洞表现','en':'Performance','tw':'各洞表現'} ,
'Result Stats':{'cn':'成绩统计','en':'Result Stats','tw':'成績統計'} ,
'Stats':{'cn':'技术统计','en':'Stats','tw':'技術統計'} ,
'Driving Accuracy':{'cn':'开球上球道率','en':'Driving Accuracy','tw':'開球上球道率'} ,
'Putting Average':{'cn':'平均推杆数','en':'Putting Average','tw':'平均推杆數'} ,
'Putts per GIR':{'cn':'GIR平均推杆数','en':'Putts per GIR','tw':'GIR平均推杆數'} ,
'Stroking Average':{'cn':'平均杆数','en':'Stroking Average','tw':'平均杆數'} ,
'Saving Accuracy':{'cn':'救球率','en':'Saving Accuracy','tw':'救球率'} ,
'Sand Saves':{'cn':'沙坑救球率','en':'Sand Saves','tw':'沙坑救球率'} ,
'Average Driving Distance':{'cn':'开球平均距离','en':'Average Driving Distance','tw':'開球平均距離'} ,
'Start':{'cn':'出发','en':'Start','tw':'出發'} ,
'Result Curve':{'cn':'成绩变化曲线','en':'Result Curve','tw':'成績變化曲線'} ,
'Result Distribution':{'cn':'成绩分布','en':'Result Distribution','tw':'成績分佈'} ,
'Winner':{'cn':'第一名','en':'Winner','tw':'第一名'}
};


// exports.getLang = getLang;
exports.getLangAll    = getLangAll;
exports.getSystemLang = getSystemLang;