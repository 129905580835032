<template>


    <transition name='fade'>
        <router-view></router-view>
    </transition>

</template>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
		background-color: #F0F3F6;
		height: 100%;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    html {
        /*谷歌浏览器字体最小字体12px*/
        -webkit-text-size-adjust: 100%;
        /*100%/none 关闭字体大小自动调整功能*/
        /*a标签及拥有 :active伪类的元素有默认的高亮框*/
        -webkit-tap-hightlight-color: transparent;
		height: 100%;
    }

    body {
        overflow-x: hidden;
        /*开启moblie网页快速滚动和回弹的效果*/
        -webkit-overflow-scrolling: touch;
        font-size: 12px;
		min-height: 100%;
    }

    * {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    a {
        display: block;
        width: 100%;
        color: #333;
        text-decoration: none;
    }

    img {
        border: 0;
        display: block;
        width: 100%;
        vertical-align: middle;
    }

    a,
    img {
        /*阻止长按页面，弹出菜单的方法。*/
        -webkit-touch-callout: none;
    }

    input,
    textarea,
    select {
        -webkit-appearance: none;
        outline: none;
        border: none;
    }

    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .clearfix:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        clear: both;
    }

    li,
    ol {
        list-style: none;
    }

    /*修改placeholder的字体颜色*/
    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
    }

    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
    }

    input:-ms-input-placeholder {
        color: #999;
    }

    input::-webkit-input-placeholder {
        color: #999;
    }
    .lf_score_other_2{
		color: #B72A47!important;
	}
	.lf_score_other_3{
		color: #000000!important;
	}
	.lf_score_other_4{
		color: #0066CC!important;
	}
</style>
