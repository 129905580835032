<template>	
	<el-row  type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="16">
			<div class="tab9con">
			    <div class="tname">
			        <div class="td td1">#</div>
					<div class="td td2">{{langs.text_player}}</div>
					<div class="td td7" v-if="xianshilie.includes('Net')">{{langs.jingganscore}}</div>
					<div class="td td7" v-if="xianshilie.includes('HDCP')">{{langs.cd}}</div>
					<div class="td td3" v-if="xianshilie.includes('Score')">{{langs.Score}}</div>
			        <div class="td td8" v-if="xianshilie.includes('Thru')">{{langs.fin}}</div>
					<template v-if="xianshilie.includes('Rx')">
			        	<div class="td td8" v-for="(item,index) in roundarr">{{langs['R'+item]}}</div>
					</template>
			        <div class="td td8" v-if="xianshilie.includes('Total')">{{langs.total}}</div>
			    </div>
			    
				<!-- <div>
					<div>
						<div class="swiper-container swiper-no-swiping" style="width: 100%;height: 1.33rem;background: #fff;position: relative;">
							<div class="lf_ad_text">广告</div>
							<div class="swiper-wrapper">
									<div class="swiper-slide" style="width: 100%;height: 100%;">
										<div class="ad" style="height: 100%;">
											<img src="" alt="" style="max-width: 100%;">
										</div>
										
									</div>
							</div>
						</div>
					</div>
				</div> -->
				<template v-for="(item,index) in list">
					<div class="trcon">
						<div class="td td1">
							<!-- class="lf_change_pos span_red span_lv span_hui" -->
							<span class="lf_change_pos">
								<div class="lf_score_pos" v-if="item.matchPlayer!=undefined&&item.matchPlayer.mp_sts!=''">{{ item.matchPlayer.mp_sts }}</div>
								<div class="lf_score_pos" v-else>{{item.playerSummary!=undefined?item.playerSummary.net_rank_code:""}}{{item.playerSummary!=undefined&&item.playerSummary.net_rank!=0?item.playerSummary.net_rank:""}}</div>
								<template v-if="rd_id>1">
									<img v-if="item.playerSummary!=undefined&&item.playerSummary.net_change>0" src="../../assets/images/GREEN.png" alt="">
									<img v-if="item.playerSummary!=undefined&&item.playerSummary.net_change<0" src="../../assets/images/RED.png" alt="">
									<img v-if="item.playerSummary!=undefined&&item.playerSummary.net_change==0" src="../../assets/images/dd.png" alt="">
									<!-- {{ item.playerSummary!=undefined?(item.playerSummary.net_change<0?Math.abs(item.playerSummary.net_change):(item.playerSummary.net_change==0?"":item.playerSummary.net_change)):"" }} -->
								</template>
							</span>
						</div>
						<div class="td td2">
							<div target="top" class='ui-link ui-linka lf_td_div' @click="gotoplayerscore(item.pl_id)">
								<div v-if="match_set.mt_country_show_type==1" class="lf_country_new">
									<img style="vertical-align: middle" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country?item.player.pl_country:'country')+'.png'"/>
								</div>
								<div v-if="match_set.mt_country_show_type==2" class="lf_country_new">
									<text>{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</text>
								</div>
								<div class="lf_player_name_divs">
									<template v-if="match_set.xueyuan_show==1&&(item.matchPlayer==undefined||item.matchPlayer.teamname==undefined||item.matchPlayer.teamname=='')">
										<div class="lf_club_name">{{ item.player.xueyuan }}</div>
									</template>
									<template v-else>
										<div class="lf_club_name" v-if="item.matchPlayer!=undefined&&item.matchPlayer.teamname!=undefined">{{ item.matchPlayer.teamname.team_ch_name }}</div>
									</template>
									<div class="lf_player_name_div">{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ item.groupDetail!=undefined&&item.groupDetail.gp_start_hole>9&&item.groupDetail.gp_start_hole<18?'*':'' }}{{ match_set.mt_amateur=='(A)'&&item.player.pl_type=='AMA'?'(A)':'' }}</div>
									<div class="lf_imgs_type">
										<div class="lf_backg_color_img" style="max-width: 0.8rem;height: 0.6rem;background-color: unset;width: auto;" v-if="item.matchPlayer!=undefined&&item.matchPlayer.mp_logo!=''&&item.matchPlayer.mp_logo!=null">
											<img style="max-width: 0.8rem;height: 0.6rem;width: auto;" :src="'http://www.wifigolf.com/club/adm/images/matches/'+mt_id+'/'+item.matchPlayer.mp_logo" alt="">
										</div>
										<div v-if="match_set.tee_show_type==1" class="lf_backg_color_img" style="color: #333; font-size: 0.14rem;font-weight: bold;">
											{{ item.matchPlayer!=undefined?item.matchPlayer.mp_teename.split("")[0]:"" }}
										</div>
										<div class="lf_backg_color_img" v-if="((match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1))||((match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0))">
											<img v-if="(match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1)" src="../../assets/images/u7.png" alt="">
											<img v-if="(match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0)" src="../../assets/images/u9.png" alt="">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="td td7" v-if="xianshilie.includes('Net')">
							<div>
								{{item.playerSummary!=undefined?item.playerSummary.totalnet:""}}
							</div>
						</div>
						<template v-if="matchmode=='ZBCD'||matchmode=='NNP_ZBCD'">
							<div class="td td7" v-if="xianshilie.includes('HDCP')">
								{{item.playerSummary!=undefined&&item.playerSummary.score!=null&&item.playerSummary.net!=null?((item.playerSummary.score-item.playerSummary.net).toFixed(1).toString().indexOf(".") < 0?((item.playerSummary.score-item.playerSummary.net).toFixed(1).toString() + ".0"):(item.playerSummary.score-item.playerSummary.net).toFixed(1)):"0.0"}}
							</div>
						</template>
						<!-- <div class="td td3">
							{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?listdata[index].playerSummary.nettoday:""}}
						</div> -->
						<template v-if="xianshilie.includes('Score')">
							<div class="td td3" v-if="false&&item.playerSummary!=undefined&&item.playerSummary.thru>=this.matchhole.hole.length" :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':'lf_score_other_3')">
								{{ item.playerSummary.score }}
							</div>
							<div class="td td3" v-else :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':(item.playerSummary.to_par==0?'lf_score_other_3':'lf_score_other_4'))">
								{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.to_par>0?("+"+item.playerSummary.to_par):(item.playerSummary.to_par==0?'E':item.playerSummary.to_par)):""}}
							</div>
						</template>
						<div class="td td8" v-if="xianshilie.includes('Thru')">
							<span class="span_hei" :class="item.playerSummary!=undefined&&item.playerSummary.sts=='C'?'span_lv':''">{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.thru>=this.matchhole.hole.length?"F":item.playerSummary.thru):(item.groupDetail!=undefined&&item.groupDetail.gp_start_time!=undefined&&item.matchPlayer.mp_sts==""&&item.groupDetail.gp_start_time!="00:00"?item.groupDetail.gp_start_time:"")}}</span>
						</div>
						<template v-if="xianshilie.includes('Rx')">
							<div class="td td8" v-for="(rd,index) in roundarr">
								{{item.playerSummaryround!=undefined&&item.playerSummaryround[rd]!=undefined?item.playerSummaryround[rd].net:""}}
							</div>
						</template>
						<div class="td td8" v-if="xianshilie.includes('Total')">
							<div>
								{{item.playerSummary!=undefined?item.playerSummary.totalscore:""}}
							</div>
						</div>
					</div>
					<Ad :match_set="match_set" v-if="index%10==9&&index!=list.length-1"></Ad>
				</template>
				<!-- <Ad v-if="match_set" :match_set="match_set"></Ad> -->
			</div>
		</el-col>
	</el-row>
</template>

<script>
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
	import Ad from "../Ad.vue";
import axios from '../../axios/request';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                outpar:'',
				inpar:'',
				mt_id:'',
				showjinji:-1,
				listdata:[],
				xianshilie: [],
				roundarr: []
		    }
		},
        name: 'Grbg',
        props: {
			list:Object,
			playoff:Object,
			match:Object,
			match_set:Object,
			matchhole:Object,
			modal:String,
			matchmode:String,
			rd_id:String
        },
		components: {
			Ad
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if(deviceWidth>=768){
				this.WebModel=true;
			}else{
				this.WebModel=false;
			}
			var params = this.$route.query;
			// var mt_id = this.getQueryVariable("MatchID")===false?31934:this.getQueryVariable("MatchID");
			var mt_id = "";
            if(this.getQueryVariable("MatchID")===false){
                if(this.getQueryVariable("mtid")===false){
                    mt_id = 31934;
                }else{
                    mt_id = this.getQueryVariable("mtid");
                }
            }else{
                mt_id = this.getQueryVariable("MatchID");
            }
			this.mt_id = mt_id;
			for(var k in this.list){
				if(this.list[k].matchPlayer.mp_sts=="MC"){
					this.showjinji = k;
					break;
				}
			}
			for(var k in this.list){
				if(this.list[k].playerSummary!=undefined){
					var temp = this.list[k].playerSummary.net-this.list[k].playerSummary.par;
					this.listdata[k] = {}
					this.listdata[k].playerSummary = {}
					this.listdata[k].playerSummary.nettoday = temp>0?("+"+Math.round(temp)):(temp==0?"E":Math.round(temp));
				}
			}
			var roundarr = [];
			for(var i=1;i<=this.match.mt_round_num;i++){
				roundarr.push(i)
			}
			this.roundarr = roundarr;
			if(this.match.club_id!=undefined&&this.match.tour_id!=undefined){
				axios('score/mobilescoreshow.json',{
					club_id: this.match.club_id,
					tour_id: this.match.tour_id,
					pagename: "GRBG_net"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			}
		},
		watch:{
			list:function(nval,oval){
				for(var k in nval){
					if(nval[k].playerSummary!=undefined){
						var temp = nval[k].playerSummary.net-nval[k].playerSummary.par;
						this.listdata[k] = {}
						this.listdata[k].playerSummary = {}
						this.listdata[k].playerSummary.nettoday = temp>0?("+"+Math.round(temp)):(temp==0?"E":Math.round(temp));
					}
				}
			},
			match:function(nval,oval){
				var roundarr = [];
				for(var i=1;i<=nval.mt_round_num;i++){
					roundarr.push(i)
				}
				this.roundarr = roundarr;
				axios('score/mobilescoreshow.json',{
					club_id: nval.club_id,
					tour_id: nval.tour_id,
					pagename: "GRBG_net"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			},
			matchmode:function(nval,oval){
				axios('score/mobilescoreshow.json',{
					club_id: this.match.club_id,
					tour_id: this.match.tour_id,
					pagename: "GRBG_net"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			},
		},
		methods:{
			gotoplayerscore(pl_id){
				var params = this.$route.query;
				var playerparams = {};
				playerparams["lang"] = params["lang"];
				playerparams["mtid"] = this.mt_id
				playerparams["plid"] = pl_id;
				playerparams["mt_round"] = this.rd_id;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: playerparams
				});
				window.open(routeUrl.href, '_self');
			},
			getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
                var returnval = "";
				for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){returnval = pair[1];}
				}
                if(returnval!=""){
                    return returnval;
                }
				return(false);
			}
		}
    }
</script>

<style scoped="scoped">
	.tab9con .tname,.tab9con .trcon{
		display: flex;
		align-items: center;
	}
	.tab9con .tname .td,.tab9con .trcon .td{
		text-align:center; 
		font-size:0.28rem;
		line-height:0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #E3E3E3;
	}
	.tab9con .tname .td,.tab9con .trcon .td:nth-last-of-type(1){
		border-right: unset;
	}
	
	.tab9con .tname .td2,.tab9con .trcon .td2{
		display: flex;
		align-items: center;
		flex-grow: 1;
	}
	.tab9con .tname .td3,.tab9con .trcon .td3{
		width: 0.7rem;
	}
	.tab9con .tname .td7,.tab9con .trcon .td7{
		width: 0.93rem;
	}
	.tab9con .tname .td8,.tab9con .trcon .td8{
		width: 0.8rem;
	}
	.tab9con .tname{
		border-left: 1px solid #025287;
	}
	.tab9con .tname .td{
		background:#025287; 
		color:#fff;
		padding: 0;
		font-size: 0.22rem;
		box-sizing: border-box;
		font-weight: bold;
		border-right: 1px solid #fff;
	}
	.tab9con .tname .td:nth-last-of-type(1){
		border-right: none;
	}
	.tab9con .trcon{
		background:#f7f7f7;
		position: relative;
		border: 1px solid #E3E3E3;
		border-top: unset;
		box-sizing: border-box;
	}
	.tab9con .trcon:nth-of-type(2n){background:#fff;}
	.tab9con{
		width: 7rem;
		margin: 0.1rem auto 0.2rem;
		border-top-left-radius: 0.1rem;
		border-top-right-radius: 0.1rem;
		overflow: hidden;
	}
	
	
	.tab9con .trcon .td1{position: relative;}
	
	.tab9con .trcon .td7 .lf_e{width: 0.68rem;height: 0.56rem; background-color: rgba(183, 42, 71, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	.tab9con .trcon .td7 .lf_p{width: 0.68rem;height: 0.56rem; background-color: rgba(85, 86, 91, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	.tab9con .trcon .td7 .lf_b{width: 0.68rem;height: 0.56rem; background-color: rgba(2, 82, 135, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	
	
	
	.tab9con .trcon .td2 .lf_td_div{
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	.tab9con .trcon .td2 .lf_country_new{
		width: 0.56rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		font-style: italic;
	}
	.tab9con .trcon .td2 .lf_country_new text{
		font-size: 0.18rem!important;
	}
	.tab9con .trcon .td2 .lf_country_new img{
		width: 0.3rem;height: 0.2rem;
	}
	.tab9con .trcon .td2 .lf_player_name_divs{
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		color: #333;
		font-weight: bold;
		font-size: 0.28rem;
	}
	.lf_club_name{
		position: absolute;
		bottom: 0;
		left: 0;
		font-size: 0.18rem;
		color: #444;
		width: 100%;
		height: 0.2rem;
		overflow: hidden;
		line-height: 0.2rem;
		text-align: left;
		font-weight: 100;
	}
	.lf_imgs_type{
		display: flex;
		align-items: center;
	}
	.lf_zanzhulogo{
		width: 0.8rem;
		height: 0.6rem;
	}
	.lf_backg_color_img{
		width: 0.24rem;
		height: 0.24rem;
		background-color: #EEEEEE;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.1rem;
	}
	.lf_backg_color_img img{
		width: 0.16rem;
		height: 0.16rem;
	}
	.tab9con .jjx{
		height:0.3rem;
		background:#FF5860; 
		width:100%; 
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tab9con .ycjjx{
		height:0.3rem;
		background:#025287; 
		width:100%; 
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.lf_change_pos{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_change_pos img{
		width: 0.05rem;
		height: 0.12rem;
	}
	.span_red{color:#f00;}
	.span_lan{color:#005288;}
	.span_hui{color:#9c9c9c;}
	.span_hei{color:#333;}
	.span_lv{color:#1fbf41;}
	@media screen and (max-width: 768px) {
		
	}
</style>
