<template>
    <navs v-if="backshow"></navs>
	<share></share>
    <Btn :match="match" :listmode="listmode" :match_set="match_set" :modal="modal" :playoff="playoff" :matchhole="matchhole" :rd_id="rd_id"></Btn>
    <Grbg v-if="modal!='NetScore'" :matchmode="matchmode" :modal="modal" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Grbg>
    <Grbg_net v-if="modal=='NetScore'&&(matchmode=='NNP_ZBCD'||matchmode=='ZBCD')" :matchmode="matchmode" :modal="modal" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Grbg_net>
    <Grbg_nnp v-if="modal=='NetScore'&&matchmode=='NNP'" :matchmode="matchmode" :modal="modal" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Grbg_nnp>
	<!-- <Ad></Ad> -->
</template>

<script>
    // @ is an alias to /src
    import navs from "../components/nav.vue";
    import Btn from "../components/Btn.vue";
	import Ad from "../components/Ad.vue";
	import Grbg from "../components/Match/GRBG.vue";
	import Grbg_net from "../components/Match/GRBG_net.vue";
	import Grbg_nnp from "../components/Match/GRBG_nnp.vue";
	import share from "../components/share.vue";
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from "../lang";
    //这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue/swiper-vue';

    import {
        Autoplay,Pagination
    } from 'swiper';
    import 'swiper/swiper-bundle.css';
    import 'swiper/swiper.min.css';
    //这里就是更新后的路径，以前是import 'swiper/css'

    //自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                banner:{},
                jiangjin:{},
				bannertr:{},
                year:(new Date()).getFullYear(),
                curyear:(new Date()).getFullYear(),
                list:[],
                match:[],
                match_set:[],
                matchhole:[],
                playoff:[],
                bygroup:"",
                listmode:"",
                matchmode:"",
                loading:false,
                backshow: false
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
			Ad,
			Grbg,
            Grbg_net,
            Grbg_nnp,
            Swiper,
            SwiperSlide,
			share
        },
        created() {
            document.title = "排名 Leaderboard";
            // mt_name=起点中巡赛·厦门东方站&mtid=31948&lang=cn&device=web&mtround=2&mt_round_num=3&mt_start_date=2023-11-13&mt_end_date=2023-11-14&sortmode=BGS&gpid=&gptype=
            // mtid=31948&mtround=2&sortmode=BGS&gpid=
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
            var params = this.$route.query;
            var mt_id = "";
            if(this.getQueryVariable("MatchID")===false){
                if(this.getQueryVariable("mtid")===false){
                    mt_id = 31934;
                }else{
                    mt_id = this.getQueryVariable("mtid");
                }
            }else{
                mt_id = this.getQueryVariable("MatchID");
            }
            // var mt_id = this.getQueryVariable("MatchID")===false?31934:this.getQueryVariable("MatchID");
            if(params.RoundID==undefined){
                if(params.mtround == undefined){
                    var rd_id = ""
                }else{
                    var rd_id = params.mtround;
                }
            }else{
                var rd_id = params.RoundID;
            }
            // var rd_id = params.RoundID==undefined?"":params.RoundID;
            var list = params.list==undefined?(params.sortmode==undefined?"":params.sortmode):params.list;
            var bygroup = params.bygroup==undefined?"":params.bygroup;
            var lang = params.lang==undefined?"":params.lang;
            var g = params.g==undefined?(params.gpid==undefined?"":params.gpid):params.g;
            var modal = params.modal==undefined?"":params.modal;
            this.modal = modal;
            this.listmode = list;
            this.bygroup=bygroup;
            this.loading = true;
            axios('match/rankbgs.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal})
            .then((res)=>{
                var data = res.data.data;
                if(rd_id == ''){
                    this.rd_id = data.match_set.rd_id;
                }else{
                    this.rd_id = rd_id;
                }
                this.list = data.list;
                for(var k in this.list){
                    if(this.list[k].groupDetail!=undefined){
                        this.list[k].groupDetail.gp_start_time=this.list[k].groupDetail.gp_start_time.substring(11,16);
                    }
                }
                this.match = data.match;
                this.match_set = data.match_set;
                this.matchhole = data.matchhole;
                this.playoff = data.playoff;
                if(data.playoff.length==0){
                    this.match.playoffhole = 0;
                }
                if(this.bygroup==1){
                    if(this.matchhole.hole.length==9){
                        this.matchmode = "group9";
                    }else{
                        this.matchmode = "group";
                    }
                }else if(this.modal!='NetScore'&&this.matchhole.hole.length==27){
                    this.matchmode = "BGS27";
                }else if(this.modal!='NetScore'&&this.matchhole.hole.length==9){
                    this.matchmode = "BGS9";
                }else if(this.modal=='NetScore'&&(this.listmode=='NNP'||this.match_set.mt_scoring_mode=='NNP'||this.match_set.mt_scoring_mode=='NNP_ZBCD')){
                    if(this.match_set.mt_scoring_mode=='NNP_ZBCD'){
                        this.matchmode = "NNP_ZBCD";
                        this.listmode='NNP_ZBCD';
                    }else{
                        this.matchmode = "NNP";
                    }
                }else if(this.modal=='NetScore'&&(this.listmode=='ZBCD'||this.match_set.mt_scoring_mode=='ZBCD')){
                    this.matchmode = "ZBCD";
                }else if(this.modal!='NetScore'){
                    this.matchmode = "BGS";
                }
                // this.matchmode="group9";
                // console.log(this.matchmode)// = "BGS9";
                this.loading = false;
            });
            var ua = navigator.userAgent.toLowerCase();
            if(ua.match(/MicroMessenger/i)=="micromessenger") {
                //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                wx.miniProgram.getEnv((res)=>{
                    if (res.miniprogram) {
                        // alert("在小程序里");
                        this.backshow = false;
                    } else {
                        // alert("不在小程序里");
                        this.backshow = true;
                    }
                })
            }else{
                // alert('不在微信里');
                this.backshow = true;
            }
        },
        setup() {
            const onSwiper = (swiper) => {
            };
            const onSlideChange = (e) => {
            };
            const autoplayOptions = {
                delay: 5000,
                disableOnInteraction: false,
                loop: false,
                pauseOnMouseEnter: true,
                reverseDirection: true
            };
            return {
                onSwiper,
                onSlideChange,
                autoplayOptions,
                modules: [Pagination,Autoplay],
            };
        },
        methods: {
			getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
                var returnval = "";
				for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){returnval = pair[1];}
				}
                if(returnval!=""){
                    return returnval;
                }
				return(false);
			}
        }

    }
</script>


<style scoped="scoped">
    
    @media screen and (max-width: 768px) {
        
    }
</style>
