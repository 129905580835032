<template>
    
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
    import axios from '../axios/request';
	import axios1 from '../axios/request1';
	import {
		wexinShare
	} from '@/assets/utils.js';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                banner:{},
                cj:{},
				fenxiangcontent:{
					title:"wifiGOLF",
					desc:"@wifiGOLF数据支持",
					imgUrl:"http://clpga.org/clpga.jpg"
				},

            };
        },
        name: 'share',
        props: {
            msg: String,
			match: Object,
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			// console.log(this.systemlang);
			let currentUrl = window.location.href;
			currentUrl = encodeURIComponent(currentUrl);
			this.year=new Date().toDateString().split(' ')[3];
			var that=this;
			setTimeout(function(){that.weixin()},1000);
			var mt_id = "";
			if(this.getQueryVariable("MatchID")===false){
			    if(this.getQueryVariable("mtid")===false){
			        mt_id = 31934;
			    }else{
			        mt_id = this.getQueryVariable("mtid");
			    }
			}else{
			    mt_id = this.getQueryVariable("MatchID");
			}
			
			axios1('https://www.wifigolf.com/api5/index.php/v5.wifigolf.share.info.json',{otp:'info',mt_id:mt_id})
			.then((res)=>{
				let data = res.data.data;
				if(this.systemlang=='en'){
					this.fenxiangcontent.title=data.match.mt_en_name==''?data.match.mt_cn_name:data.match.mt_en_name;
					this.fenxiangcontent.desc=data.qc.cr_en_name==''?data.qc.cr_cn_name:data.qc.cr_en_name;
					this.fenxiangcontent.imgUrl=data.share_img;
				}else{
					this.fenxiangcontent.title=data.match.mt_cn_name;
					this.fenxiangcontent.desc=data.qc.cr_cn_name;
					this.fenxiangcontent.imgUrl=data.share_img;
				}
			});

        },
		methods: {
			weixin() {
				var that=this;
				//请求微信配置参数接口（获取签名），由后台给接口给
				var urls = window.location.href.split('#')[0];
				//看后台请求接口是get/post
				axios1('https://www.wifigolf.com/home/mobile/index.php/Home/Rank/wxsdk.html?url='+encodeURIComponent(urls)).then(res => {
					
					if (res.status == 200) {
						//微信加签
						var obj = {
							appId: res.data.appId,
							nonceStr: res.data.nonceStr,
							signature: res.data.signature,
							timestamp: res.data.timestamp,
							jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
						};
						//分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
						//var navLang = localStorage.getItem('lang')||'cn';
						var url = window.location.href;
							//url = url.split('?')[0];   
							//url = window.location.href+'?&lang='+navLang+'&type='+that.tFlag;
							console.log('url---',url);
						// var shareWxLink = encodeURIComponent(url);加密
						let shareData = {
							title: that.fenxiangcontent.title+'【成绩直播】', // 分享标题
							desc:that.fenxiangcontent.desc+'@wifiGOLF数据支持',
							//link: res.data.linkUrl,
							link: url,
							imgUrl: that.fenxiangcontent.imgUrl // 分享图标
						};
						//引用
						wexinShare(obj, shareData);
						// alert('获取sdk成功！');
					} else {
						alert('获取sdk参数失败！');
					}
				});
			},
			getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
			    var returnval = "";
				for (var i=0;i<vars.length;i++) {
			        var pair = vars[i].split("=");
			        if(pair[0] == variable){returnval = pair[1];}
				}
			    if(returnval!=""){
			        return returnval;
			    }
				return(false);
			}
			
		}


    }
</script>

<style scoped>
    .lf_new_top_{
    	width: 100%;
    	height:40px;
    }
    .lf_new_top_box{
    	display: flex;
    	align-items: center;
    	height: 40px;
    	background: #005288;
    	padding: 0 2.5%;
    	position: fixed;
    	left: 0;
    	top:0;
    	width: 100%;
    	font-size: 12px;
    	z-index: 999;
    }
    .lf_new_top_box .toBack{
    	width: 15px;
    	height: 22px;
    	margin-right: 10px;
    	margin-top: 4px;
    }
	@media screen and (max-width: 768px) {
		
	}
</style>
